.cal-week-box {
  position: relative;
  [data-event-class] {
    white-space: nowrap;
    height: 30px;
    margin: 1px 1px;
    line-height: 30px;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-left: 10px;
  }
  .cal-column {
    position: absolute;
    height: 100%;
    z-index: -1;
  }
  .arrow-before,
  .arrow-after {
    position: relative;
  }
  .arrow-after:after {
    content:"";
    position: absolute;
    top: 0px;
    width: 0;
    height: 0;
    right: 0;
    border-top: 15px solid #ffffff;
    border-left: 8px solid;
    border-bottom: 15px solid #FFFFFF;
  }
  .arrow-before:before {
    content:"";
    position: absolute;
    top: 0px;
    width: 0;
    height: 0;
    left: 1px;
    border-top: 15px solid transparent;
    border-left: 8px solid #FFFFFF;
    border-bottom: 15px solid transparent;
  }
}

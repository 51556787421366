[class*="cal-cell"] {
  float: left;
  margin-left: 0;
  min-height: 1px;
}

.cal-row-fluid {
  width: 100%;
  *zoom: 1;
}

.cal-row-fluid:before,
.cal-row-fluid:after {
  display: table;
  content: "";
  line-height: 0;
}

.cal-row-fluid:after {
  clear: both;
}

.cal-row-fluid [class*="cal-cell"] {
  display: block;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
  margin-left: 0%;
  *margin-left: -0.05213764337851929%;
}

.cal-row-fluid [class*="cal-cell"]:first-child {
  margin-left: 0;
}

.cal-row-fluid .controls-row [class*="cal-cell"] + [class*="cal-cell"] {
  margin-left: 0%;
}
.cal-row-fluid .cal-cell7 {
  width: 100%;
  *width: 99.94669509594883%;
}
.cal-row-fluid .cal-cell6 {
  width: 85.71428571428571%;
  *width: 85.66098081023453%;
}
.cal-row-fluid .cal-cell5 {
  width: 71.42857142857142%;
  *width: 71.37526652452024%;
}
.cal-row-fluid .cal-cell4 {
  width: 57.14285714285714%;
  *width: 57.089552238805965%;
}
.cal-row-fluid .cal-cell3 {
  width: 42.857142857142854%;
  *width: 42.80383795309168%;
}
.cal-row-fluid .cal-cell2 {
  width: 28.57142857142857%;
  *width: 28.518123667377395%;
}
.cal-row-fluid .cal-cell1 {
  width: 14.285714285714285%;
  *width: 14.232409381663112%;
}
.cal-week-box .cal-offset7,
.cal-row-fluid .cal-offset7,
.cal-row-fluid .cal-offset7:first-child {
  margin-left: 100%;
  *margin-left: 99.89339019189765%;
}

.cal-week-box .cal-offset6,
.cal-row-fluid .cal-offset6,
.cal-row-fluid .cal-offset6:first-child {
  margin-left: 85.71428571428571%;
  *margin-left: 85.60767590618336%;
}
.cal-week-box .cal-offset5,
.cal-row-fluid .cal-offset5,
.cal-row-fluid .cal-offset5:first-child {
  margin-left: 71.42857142857142%;
  *margin-left: 71.32196162046907%;
}
.cal-week-box .cal-offset4,
.cal-row-fluid .cal-offset4,
.cal-row-fluid .cal-offset4:first-child {
  margin-left: 57.14285714285714%;
  *margin-left: 57.03624733475479%;
}
.cal-week-box .cal-offset3,
.cal-row-fluid .cal-offset3,
.cal-row-fluid .cal-offset3:first-child {
  margin-left: 42.857142857142854%;
  *margin-left: 42.750533049040506%;
}
.cal-week-box .cal-offset2,
.cal-row-fluid .cal-offset2,
.cal-row-fluid .cal-offset2:first-child {
  margin-left: 28.57142857142857%;
  *margin-left: 28.46481876332622%;
}
.cal-week-box .cal-offset1,
.cal-row-fluid .cal-offset1,
.cal-row-fluid .cal-offset1:first-child {
  margin-left: 14.285714285714285%;
  *margin-left: 14.17910447761194%;
}

.cal-row-fluid .cal-cell1 {
  width: 14.285714285714285%;
  *width: 14.233576642335766%;
}

[class*="cal-cell"].hide,
.cal-row-fluid [class*="cal-cell"].hide {
  display: none;
}

[class*="cal-cell"].pull-right,
.cal-row-fluid [class*="cal-cell"].pull-right {
  float: right;
}